/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import {
  Menu,
  Dropdown,
  Popover,
  Switch,
  Spin,
  Button,
  Badge,
  MenuProps,
} from "antd";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  BellOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import useNotifications from "hooks/query/notifications/useNotifications";
import { useWidth } from "utils/helpers/use-is-mobile";
import { useQueryClient } from "react-query";
import { Roles } from "utils/helpers/static_data";
import Color from "utils/helpers/export-sass-colors";
import ReadAllNotifications from "hooks/query/notifications/readAllNotifications";
import ReadOneNotification from "hooks/query/notifications/readOneNotification";
import useLogout from "hooks/auth/logout";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ar from "dayjs/locale/ar";
import en from "dayjs/locale/en";
import UnratedTicket from "components/ticket/unrated/unratedTicket";
import { isClient } from "utils/helpers/check-roles";
import useNotificationsNumber from "hooks/query/notifications/useNotificationsNumber";
import i18next, { t } from "i18next";
import { direction } from "utils/ui/LocaleDirection";
import TechnicianStatusButton from "./header/TechnicianStatusButton";
import { SidebarContext } from "state/SideBarContext";
import { MdMenu } from "react-icons/md";

import InfiniteScroll from "react-infinite-scroll-component";
import usePagination from "hooks/others/usePagination";

dayjs.extend(relativeTime);

let isInit = true;

const Header = (props: any) => {
  const queryClient = useQueryClient();
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);
  const notiCon = useRef<any>(null);
  const logoutUser = useLogout();

  const { isMobile } = useWidth();
  const [IsDrawerVisiable, setIsDrawerVisiable] = useState(false);

  const [NotificationsSetting, setNotificationsSetting] = useState<any>({
    page: 1,
    loadingMore: false,
    data: [],
    total: 0,
  });

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const loading = (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spin indicator={antIcon} size="small" />
    </div>
  );
  const [unread, setUnread] = useState<boolean>(false);
  const [Visable, setVisable] = useState<any>(false);

  const { page } = usePagination(8);

  const {
    data: notifications,
    isLoading,
    isFetching,
    refetch,
    fetchNextPage,
  } = useNotifications(page, unread, Visable);

  const hasMore =
    notifications?.pageParams.length! < notifications?.pages[0].meta.lastPage!;

  const notificationsNumber = useNotificationsNumber();

  const ReadAllNotification = ReadAllNotifications();
  const ReadOne = ReadOneNotification();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  useEffect(() => {
    queryClient.removeQueries("Notifications");
  }, []);

  function handleNext() {
    if (hasMore) {
      fetchNextPage();
    }
  }

  const handleLanguageChange = (e: any) => {
    localStorage.setItem("lang", e.key);
    i18next.changeLanguage(e.key).then(() => {
      window.location.reload();
    });
    if (e.key === "ar") {
      document.documentElement.style.setProperty("direction", "rtl");
    } else {
      document.documentElement.style.setProperty("direction", "ltr");
    }
  };

  const languageItems: MenuProps["items"] = [
    {
      key: "ar",
      label: <a>AR</a>,
    },
    {
      key: "en",
      label: <a>EN</a>,
    },
  ];

  const menu = (
    <Menu>
      <NavLink
        className={({ isActive }: any) => (isActive ? "LinkActive" : "")}
        to={{ pathname: `/profile` }}
        onClick={() => setIsDrawerVisiable(false)}
      >
        <Menu.Item style={{ textAlign: "center" }}>{t("profile")}</Menu.Item>
      </NavLink>
      <Menu.Item
        onClick={() => {
          logoutUser.mutateAsync();
          setIsDrawerVisiable(false);
        }}
        className="redbg"
        style={{ textAlign: "center", color: Color.red }}
      >
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  const content = (
    <InfiniteScroll
      dataLength={notifications?.pageParams.length ?? 0}
      next={handleNext}
      hasMore={hasMore}
      loader={loading}
      className="notificationCon"
      ref={notiCon}
      height="40vh"
    >
      <div>
        {notifications?.pages.map((page) => {
          return page.data.map((Notification) => (
            <div key={Notification.id}>
              {(Notification.model_type === "Ticket" ||
                Notification.model_type === "User") && (
                <div
                  className={
                    Notification.is_read !== null &&
                    Notification.is_read.toString() === "1"
                      ? "notificationBody"
                      : "notificationBody active"
                  }
                  key={Notification.id}
                >
                  <Link
                    to={
                      Notification.model_type === "Ticket"
                        ? `/${
                            isClient(User?.role_id!) ? "c-tickets" : "tickets"
                          }/${Notification.model_id}${
                            !isClient(User?.role_id!)
                              ? "?not_event=" + Notification.pointer_type
                              : ""
                          } `
                        : User?.role_id !== Roles.client &&
                          Notification.model_type === "User"
                        ? `/users/${Notification.model_id}`
                        : ""
                    }
                  >
                    <Badge.Ribbon
                      placement="start"
                      text={`#${
                        isClient(User?.role_id!)
                          ? Notification.public_id
                          : Notification.model_id
                      }`}
                    >
                      <div
                        onClick={() => {
                          queryClient.removeQueries("Notifications");
                          setVisable(false);
                        }}
                        className={"notificationCard"}
                      >
                        <div className="title">{Notification.title}</div>
                        <div className="des">{Notification.content}</div>

                        <div
                          className="time"
                          style={{
                            left: i18next.language === "ar" ? "5px" : "",
                            right: i18next.language === "en" ? "5px" : "",
                          }}
                        >
                          {dayjs(Notification.created_at)
                            .locale(
                              i18next.language === "ar" ? ar.name : en.name
                            )
                            .fromNow()}
                        </div>
                      </div>
                    </Badge.Ribbon>
                  </Link>
                  {Notification.is_read !== null &&
                    Notification.is_read.toString() === "0" && (
                      <Button
                        className="read"
                        style={{
                          left: i18next.language === "ar" ? "10px" : "",
                          right: i18next.language === "en" ? "10px" : "",
                        }}
                        onClick={() => {
                          ReadOne.mutateAsync(Notification.id).then(() => {
                            refetch().then(() => {
                              queryClient.removeQueries("Notifications");
                            });
                          });
                        }}
                        type={"link"}
                        icon={
                          <CheckCircleOutlined style={{ fontSize: "1.2rem" }} />
                        }
                      >
                        <span className="title">{t("mark_as_read")}</span>
                      </Button>
                    )}
                </div>
              )}
            </div>
          ));
        })}
      </div>

      {/* {<ModalOverlay isLoading={isLoading} />} */}
    </InfiniteScroll>
  );

  return (
    <div dir={direction[i18next.language]}>
      {isClient(User?.role_id!) && <UnratedTicket />}
      <div className="header">
        <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
          <>
            <div>
              <Button
                color="primary"
                onClick={toggleSidebar}
                icon={<MdMenu />}
              ></Button>
            </div>
          </>

          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Popover
              title={
                <>
                  <div>{t("notifications")}</div>
                  <span style={{ fontSize: "14px" }}>
                    <Button
                      size="small"
                      type="link"
                      disabled={
                        NotificationsSetting?.total?.notification_number === 0
                      }
                      onClick={() => {
                        ReadAllNotification.mutateAsync().then(() => {
                          refetch().then(() => {
                            queryClient.removeQueries("Notifications");
                          });
                        });
                      }}
                      style={{ margin: "0 10px" }}
                      loading={ReadAllNotification.isLoading}
                    >
                      {t("mark_all_as_read")}
                    </Button>
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    <Switch
                      size="small"
                      checked={unread}
                      disabled={
                        notificationsNumber.data?.data
                          .number_of_notifications === 0
                      }
                      onClick={() => {
                        setUnread(!unread);
                        refetch().then(() => {
                          queryClient.removeQueries("Notifications");
                        });
                      }}
                      style={{ margin: "0 10px" }}
                      loading={isFetching}
                    />
                    {t("unread_only")}
                  </span>
                </>
              }
              className="notification"
              placement={isMobile ? "bottomLeft" : "bottom"}
              content={content}
              trigger="click"
              onOpenChange={() => {
                if (!Visable) queryClient.removeQueries("Notifications");
                setVisable(!Visable);
              }}
              open={Visable}
            >
              <div className="notificationBtn pointer">
                <BellOutlined />
                {notificationsNumber.data?.data.number_of_notifications !==
                  0 && (
                  <div className="notificationNum">
                    {notificationsNumber.data?.data.number_of_notifications}
                  </div>
                )}
              </div>
            </Popover>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Dropdown
            trigger={["hover"]}
            menu={{
              items: languageItems,
              onClick: handleLanguageChange,
              selectable: true,
            }}
          >
            <Button
              style={{
                margin: "0 1rem",
                backgroundColor: "transparent",
                color: Color.grayAC,
              }}
              onClick={(e) => e.preventDefault()}
            >
              {localStorage.getItem("lang")?.toUpperCase()}
            </Button>
          </Dropdown>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              direction: "rtl",
            }}
          >
            <Dropdown
              disabled={logoutUser.isLoading}
              trigger={["click"]}
              overlay={menu}
              placement="bottomLeft"
            >
              <div className="adminInfo">
                <img src={User?.avatar ? User?.avatar : ""} alt="" />
                <div className="cardText">
                  <div className="title">
                    {User?.full_name !== null && User?.full_name}
                  </div>
                  <div className="des">{User?.role_name}</div>
                </div>
                {logoutUser.isLoading && (
                  <Spin
                    style={{ color: "white", marginRight: "8px" }}
                    indicator={<LoadingOutlined />}
                  />
                )}
              </div>
            </Dropdown>
            {User?.role_id == Roles.technical && (
              <TechnicianStatusButton isMobile={isMobile} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
