import {
  Col,
  Form,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Spin,
  Switch,
  UploadFile,
} from "antd";
import { useEffect, useState } from "react";

import PermissionsGate, { hasPermission } from "components/permissionsGate";

import { Roles, Ticket as TicketStatus } from "utils/helpers/static_data";
import { filters } from "components/filters/ticketsFillters";

import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";

import { submitFormTicketClientRequest } from "DTO/clientRequests/create";
import AddClientRequest from "hooks/query/clientRequests/AddClientRequests";
import ServicesInput from "components/ticket/addTicketModalInputs/ServicesInput";
import WorkPlaceInput from "components/ticket/addTicketModalInputs/WorkPlaceInput";
import DescriptionInput from "components/ticket/addTicketModalInputs/DescriptionInput";
import { isClient } from "utils/helpers/check-roles";
import CompanyPlaceUserInput from "components/ticket/addTicketModalInputs/CompanyPlaceUserInput";
import RequestedByInput from "components/ticket/addTicketModalInputs/RequestedByInput";
import LocationInput from "components/ticket/addTicketModalInputs/LocationInput";
import PhoneListInput from "components/ticket/addTicketModalInputs/PhoneListInput";
import PriorityInput from "components/ticket/addTicketModalInputs/PriorityInput";
import UploadInput from "components/ticket/addTicketModalInputs/UploadInput";

import { SplitPhone } from "utils/helpers/split-phone";

import "./style.css";

import AddTicket from "hooks/query/tickets/addTicket";
import { submitFormTicket } from "DTO/tickets/create";

import { CloseOutlined } from "@ant-design/icons";
import { ClientRequestModel } from "models/clientRequests";
import { TicketModel } from "models/tickets";
import { t } from "i18next";
import TicketInput from "components/ticket/forms/inputs";
import { request } from "http";
import ClientRequest from "pages/clientRequests/clientRequest/ClientRequest";
import TicketsDateInput from "components/ticket/forms/inputs/ticketDateInput";

interface addTicketModalProps {
  clientRequest?: ClientRequestModel | TicketModel;
  isOpen: boolean;
  setIsOpen: any;
}

const AddTicketModal = (props: addTicketModalProps) => {
  const [FileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [ImageList, setImageList] = useState<UploadFile<any>[]>([]);

  const [form] = Form.useForm<submitFormTicketClientRequest>();

  const [Status, setStatus] = useState<filters["Status"]>(TicketStatus.create);

  const [requestType, setRequestType] = useState("single-visit");
  const handleRequestTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    setRequestType(value);
  };

  const createTicket = AddTicket();

  const createClientRequest = AddClientRequest();

  useEffect(() => {
    form.resetFields();
  }, [props.clientRequest?.id, props.clientRequest?.client_request_id]);

  const AddNewTicket = (e: submitFormTicket) => {
    const formData = new FormData();
    formData.append(
      "client_request_id",
      props?.clientRequest?.client_request_id.toString()!
    );
    if (User?.role_id === Roles.client) {
      formData.append("requested_by", User?.id.toString());
    }
    if (e.lat !== undefined && e.lon !== undefined) {
      formData.append("lat", `${e.lat}`);
      formData.append("lon", `${e.lon}`);
    } else if (
      !(e.lat !== undefined && e.lon !== undefined) &&
      User?.role_id === Roles.client &&
      User?.company !== null
    ) {
      formData.append("lat", `${User?.company?.places[0].location.lat}`);
      formData.append("lon", `${User?.company?.places[0].location.lon}`);
    }
    if (
      e.contacts &&
      e.contacts.some(
        (contact) =>
          contact.phone.phone !== undefined && contact.phone.phone !== ""
      )
    ) {
      e.contacts.forEach((contact) => {
        formData.append(
          "phone[]",
          JSON.stringify({
            phone: `+${contact.phone.code}${contact.phone.phone}`,
            title: contact.title,
          })
        );
      });
    } else if (
      !e.contacts?.some(
        (contact) =>
          contact.phone.phone !== undefined && contact.phone.phone !== ""
      ) &&
      User?.role_id === Roles.client
    ) {
      formData.append(
        "phone[]",
        JSON.stringify({
          phone: User?.phone_number,
          title: User?.full_name,
        })
      );
    }
    if (hasPermission(["browse_categories"])) {
      if (typeof e.category === "object" && e.category?.value) {
        formData.append("category_id", e.category?.value);
      } else if (typeof e.category === "string") {
        formData.append("category_id", e.category);
      }
      if (typeof e.service === "object" && e.service.value) {
        formData.append("service_id", e.service.value);
      } else if (typeof e.service === "string") {
        formData.append("service_id", e.service);
      }
      if (typeof e.workplace === "object" && e.workplace.value) {
        formData.append("workplace_id", e.workplace.value);
      } else if (typeof e.workplace === "string") {
        formData.append("workplace_id", e.workplace);
      }
    }
    if (e.description) {
      formData.append("description", e.description);
    }
    if (e.priority) {
      formData.append("priority", e.priority);
    }

    if (e.location_name !== undefined && e.location_name !== "") {
      formData.append("location_name", e.location_name);
    }
    if (hasPermission(["browse_users"]) && e.requested_by) {
      if (typeof e.requested_by === "object" && e.requested_by.value) {
        formData.append("requested_by", e.requested_by.value);
      } else if (typeof e.requested_by === "string") {
        formData.append("requested_by", e.requested_by);
      }
    }
    if (hasPermission(["browse_users"]) && e.requested_by_user) {
      if (
        typeof e.requested_by_user === "object" &&
        e.requested_by_user.value
      ) {
        formData.append("requested_by", e.requested_by_user.value);
      } else if (typeof e.requested_by_user === "string") {
        formData.append("requested_by", e.requested_by_user);
      }
    }

    if (e?.company_place !== undefined && User?.role_id !== Roles.client) {
      if (typeof e.company_place === "object") {
        if (e.company_place.value !== undefined)
          formData.append("company_place_id", e.company_place.value);
      } else {
        formData.append("company_place_id", e.company_place);
      }
    }
    if (e?.company_place_user !== undefined && User?.role_id === Roles.client) {
      if (typeof e.company_place_user === "object") {
        if (e.company_place_user.value !== undefined)
          formData.append("company_place_id", e.company_place_user.value);
      } else {
        formData.append("company_place_id", e.company_place_user);
      }
    }

    if (Status === TicketStatus.create) {
      if (FileList.length !== 0) {
        FileList.forEach((element) => {
          formData.append(
            "attachments[]",
            element["originFileObj"] as unknown as string
          );
        });
      }
      if (ImageList.length !== 0) {
        ImageList.forEach((element) => {
          formData.append(
            "images[]",
            element["originFileObj"] as unknown as string
          );
        });
      }
      createTicket.mutateAsync(formData).then(() => {
        props.setIsOpen(false);
        form.resetFields();
      });
    }
  };

  const AddNewClientRequest = (e: submitFormTicketClientRequest) => {
    const formData = new FormData();

    if (User?.role_id === Roles.client) {
      formData.append("requested_by", User?.id.toString());
    }
    if (e.lat !== undefined && e.lon !== undefined) {
      formData.append("lat", `${e.lat}`);
      formData.append("lon", `${e.lon}`);
    } else if (
      !(e.lat !== undefined && e.lon !== undefined) &&
      User?.role_id === Roles.client &&
      User?.company !== null
    ) {
      formData.append("lat", `${User?.company?.places[0].location.lat}`);
      formData.append("lon", `${User?.company?.places[0].location.lon}`);
    }
    if (
      e.contacts &&
      e.contacts.some(
        (contact) =>
          contact.phone.phone !== undefined && contact.phone.phone !== ""
      )
    ) {
      e.contacts.forEach((contact) => {
        formData.append(
          "phone[]",
          JSON.stringify({
            phone: `+${contact.phone.code}${contact.phone.phone}`,
            title: contact.title,
          })
        );
      });
    } else if (
      !e.contacts?.some(
        (contact) =>
          contact.phone.phone !== undefined && contact.phone.phone !== ""
      ) &&
      User?.role_id === Roles.client
    ) {
      formData.append(
        "phone[]",
        JSON.stringify({
          phone: User?.phone_number,
          title: User?.full_name,
        })
      );
    }
    if (hasPermission(["browse_categories"])) {
      if (typeof e.category === "object" && e.category?.value) {
        formData.append("category_id", e.category?.value);
      } else if (typeof e.category === "string") {
        formData.append("category_id", e.category);
      }
      if (typeof e.service === "object" && e.service.value) {
        formData.append("service_id", e.service.value);
      } else if (typeof e.service === "string") {
        formData.append("service_id", e.service);
      }
      if (typeof e.workplace === "object" && e.workplace.value) {
        formData.append("workplace_id", e.workplace.value);
      } else if (typeof e.workplace === "string") {
        formData.append("workplace_id", e.workplace);
      }
    }
    if (e.description) {
      formData.append("description", e.description);
    }
    if (e.priority) {
      formData.append("priority", e.priority);
    }

    if (e.location_name !== undefined && e.location_name !== "") {
      formData.append("location_name", e.location_name);
    }
    if (hasPermission(["browse_users"]) && e.requested_by) {
      if (typeof e.requested_by === "object" && e.requested_by.value) {
        formData.append("requested_by", e.requested_by.value);
      } else if (typeof e.requested_by === "string") {
        formData.append("requested_by", e.requested_by);
      }
    }
    if (hasPermission(["browse_users"]) && e.requested_by_user) {
      if (
        typeof e.requested_by_user === "object" &&
        e.requested_by_user.value
      ) {
        formData.append("requested_by", e.requested_by_user.value);
      } else if (typeof e.requested_by_user === "string") {
        formData.append("requested_by", e.requested_by_user);
      }
    }

    if (e?.company_place !== undefined && User?.role_id !== Roles.client) {
      if (typeof e.company_place === "object") {
        if (e.company_place.value !== undefined)
          formData.append("company_place_id", e.company_place.value);
      } else {
        formData.append("company_place_id", e.company_place);
      }
    }
    if (e?.company_place_user !== undefined && User?.role_id === Roles.client) {
      if (typeof e.company_place_user === "object") {
        if (e.company_place_user.value !== undefined)
          formData.append("company_place_id", e.company_place_user.value);
      } else {
        formData.append("company_place_id", e.company_place_user);
      }
    }

    if (Status === TicketStatus.create) {
      if (FileList.length !== 0) {
        FileList.forEach((element) => {
          formData.append(
            "attachments[]",
            element["originFileObj"] as unknown as string
          );
        });
      }
      if (ImageList.length !== 0) {
        ImageList.forEach((element) => {
          formData.append(
            "images[]",
            element["originFileObj"] as unknown as string
          );
        });
      }

      formData.append(
        "type",
        requestType === "contract" ? `contract` : `single_visit`
      );
      if (requestType === "contract") {
        if (e.contract_start_date)
          formData.append("contract_start_date", e.contract_start_date);
        if (e.contract_end_date)
          formData.append("contract_end_date", e.contract_end_date!);
        if (e.visits_number)
          formData.append("visits_number", `${e.visits_number}`);
      }

      createClientRequest.mutateAsync(formData).then(() => {
        props.setIsOpen(false);
        form.resetFields();
      });
    }
  };

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);
  const [category, setCategory] = useState<number>(0);
  const [company, setCompany] = useState<any>("0");
  const [companyPlace, setCompanyPlace] = useState<any>("0");
  const [userContact, setUserContact] = useState<any>(null);
  const [CompanyInputs, setCompanyInputs] =
    useState<"client" | "company">("company");

  useEffect(() => {
    if (props.clientRequest && props.clientRequest?.company_place) {
      setCompanyPlace(props.clientRequest?.company_place?.id);
    }
    if (!props.clientRequest) {
      setCompanyInputs("company");
    } else if (props.clientRequest && props.clientRequest?.company_place) {
      setCompanyInputs("company");
    } else if (props.clientRequest && !props.clientRequest?.company_place) {
      setCompanyInputs("client");
    }
  }, [props.clientRequest, props.isOpen]);

  const handleClose = () => {
    props.setIsOpen(false);
    form.resetFields();
    setCategory(0);
    if (!props.clientRequest) {
      setCompany("0");
      setCompanyPlace("0");
    }
  };

  const formInitialValues = props.clientRequest
    ? {
        company_place: props.clientRequest?.company_place
          ? {
              label: `${
                props.clientRequest?.company_place?.company?.name
                  ? props.clientRequest?.company_place?.company?.name
                  : props.clientRequest?.company_place?.company?.name_en
              } - ${props.clientRequest.company_place?.name}`,
              value: props.clientRequest?.company_place?.id.toString(),
            }
          : null,
        requested_by_user:
          props.clientRequest.user &&
          props.clientRequest?.company_place === null
            ? {
                label: props.clientRequest.user.full_name,
                value: props.clientRequest.user.id.toString(),
              }
            : undefined,
        lat: props.clientRequest.company_place
          ? props.clientRequest.company_place.location.lat
          : undefined,
        lon: props.clientRequest.company_place
          ? props.clientRequest.company_place.location.lon
          : undefined,
        contacts:
          props.clientRequest.user.contacts &&
          props.clientRequest.user.contacts?.length > 0
            ? props.clientRequest.user.contacts?.map((value: any) => {
                return {
                  phone: { ...SplitPhone(value.value) },
                  title: props.clientRequest?.user.full_name,
                };
              })
            : [{ phone: { short: "SY" }, title: "" }],
      }
    : undefined;

  return (
    <Modal
      open={props.isOpen}
      width={1400}
      onCancel={handleClose}
      onOk={form.submit}
      maskClosable={false}
      confirmLoading={createTicket.isLoading || createClientRequest.isLoading}
      bodyStyle={{
        padding: "3rem",
        backgroundColor: "#f4f4f4",
        maxHeight: "80vh",
        overflowY: "scroll",
      }}
      closable={false}
      centered
    >
      <>
        <div className="modal-header">
          <h3 className="title">
            {props.clientRequest
              ? t("add_ticket_for_request") +
                " " +
                props.clientRequest.client_request_id
              : t("add_request")}
          </h3>
          <span className="close-icon" onClick={handleClose}>
            <CloseOutlined />
          </span>
        </div>
        <Form
          style={{ marginTop: "1rem" }}
          key={props.clientRequest?.client_request_id}
          layout="vertical"
          onFinish={props.clientRequest ? AddNewTicket : AddNewClientRequest}
          form={form}
          requiredMark={false}
          scrollToFirstError={{ block: "center", behavior: "smooth" }}
          initialValues={formInitialValues}
        >
          <Row>
            <Col span={11} xs={24} sm={24} lg={11}>
              <PermissionsGate scopes={["browse_categories"]}>
                <ServicesInput
                  category={category}
                  setCategory={setCategory}
                  form={form}
                  Status={Status}
                />
                <WorkPlaceInput form={form} Status={Status} />
              </PermissionsGate>
              {/* <TicketInput.Assignments
                Status={Status}
                form={form}
                disabled={!hasPermission(["add_assignments"])}
              /> */}
              <DescriptionInput form={form} Status={Status} />
              {isClient(User?.role_id!) && User?.company !== null && (
                <CompanyPlaceUserInput
                  form={form}
                  Status={Status}
                  setCompanyPlace={setCompanyPlace}
                />
              )}
              {!isClient(User?.role_id!) && (
                <RequestedByInput
                  form={form}
                  Status={Status}
                  setCompanyPlace={setCompanyPlace}
                  setUserContact={setUserContact}
                  CompanyInputs={CompanyInputs}
                  setCompanyInputs={setCompanyInputs}
                  clientRequest={props.clientRequest}
                  company={company}
                  setCompany={setCompany}
                />
              )}
              <LocationInput
                form={form}
                Status={Status}
                companyPlace={companyPlace}
              />
              <PhoneListInput
                form={form}
                Status={Status}
                companyPlace={companyPlace}
                CompanyInputs={CompanyInputs}
                userContact={userContact}
              />
            </Col>
            <Col span={1} lg={1}></Col>
            <Col span={11} xs={24} sm={24} lg={11}>
              {!props.clientRequest && (
                <div style={{ textAlign: "center" }}>
                  <h3>{t("request_type")}</h3>
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    onChange={handleRequestTypeChange}
                    value={requestType}
                  >
                    <Radio value="contract">{t("contract")}</Radio>
                    <Radio value="single-visit">{t("single_visit")}</Radio>
                  </Radio.Group>
                </div>
              )}
              {requestType === "contract" && !props.clientRequest && (
                <TicketsDateInput form={form} />
              )}
              <PriorityInput form={form} Status={Status} />
              <UploadInput
                form={form}
                Status={Status}
                FileList={FileList}
                ImageList={ImageList}
                setFileList={setFileList}
                setImageList={setImageList}
              />
            </Col>
          </Row>
        </Form>
      </>
    </Modal>
  );
};

export default AddTicketModal;
