import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { DeviceUUID } from "device-uuid";
import { Token } from "utils/helpers/encrypt-decrypt-user";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyBGwlgbi2_NwcqIKJ4AuBbhFQfK6-hib7M",
  authDomain: "alkamal-pro.firebaseapp.com",
  projectId: "alkamal-pro",
  storageBucket: "alkamal-pro.appspot.com",
  messagingSenderId: "1071162280742",
  appId: "1:1071162280742:web:48c8f8eb93e7e69dfe0fe7",
});
if (firebaseApp) {
}

/**
 * disable for localhost
 */
export const messaging = getMessaging(firebaseApp);
// export const messaging = "";

export const getTokenAsync = () => {
  return getToken(messaging, {
    vapidKey:
      "BL2BIPdZNH77WL5jBeH47r-rw-gG2D99TboouvaLj583KIT5zFlfRMlVWcs6wRxQ9JyOuBukaQBLzIgKECNGNP8",
  })
    .then((currentToken) => {
      if (currentToken) {
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        var uuid = new DeviceUUID().get();
        if (Token.Get() !== null)
          axios.post(
            process.env.REACT_APP_API_URL + "api/add-firebase-token",
            {
              firebase_token: currentToken,
              device_id: uuid,
              platform: "Web",
              version: `${process.env.REACT_APP_VERSION}`,
            },
            {
              headers: {
                Authorization: `Bearer ${Token.Get()}`,
              },
            }
          );
        return currentToken;
      }
    })
    .catch((err) => {
      console.log("Error firebase", err);
      // catch error while creating clieernt token
    });
};
