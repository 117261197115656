import dayjs from "dayjs";

const Footer = () => {
  return (
    <div className="footer">
      <div className="version">Version {process.env.REACT_APP_VERSION}</div>
      <div className="copyright">
        <a
          href={"https://www.alkamal-limited.com/"}
          rel="noreferrer"
          target={"_blank"}
        >
          ALKAMAL SOLUTIONS
        </a>{" "}
        {dayjs().format("YYYY")}©
      </div>
    </div>
  );
};

export default Footer;
