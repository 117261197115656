import { useLocation, matchPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu, Layout } from "antd";
import { TeamOutlined, AppstoreOutlined } from "@ant-design/icons";
import { hasPermission } from "components/permissionsGate";
import { useEffect, useState } from "react";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { Link } from "react-router-dom";
import { isClient } from "utils/helpers/check-roles";
import { Roles } from "utils/helpers/static_data";
import { MdOutlineDashboard } from "react-icons/md";
import { BsFiles } from "react-icons/bs";
import { IoTicketOutline } from "react-icons/io5";
import { GrUserWorker } from "react-icons/gr";
import { TbReportAnalytics } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { FiUsers } from "react-icons/fi";
import { useWidth } from "utils/helpers/use-is-mobile";
import { HiOutlineChartBarSquare } from "react-icons/hi2";
import { GoGear } from "react-icons/go";
import { BiCategoryAlt } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa";
import { IoList } from "react-icons/io5";
import useClassifications from "hooks/query/classifications/useClassifications";

const { Sider } = Layout;
const { SubMenu } = Menu;

const renderSidebarMenuItems = ({ items, path }: any) => (
  <Menu mode="inline" selectedKeys={[path]}>
    {items.reduce(
      (ev: any, item: any) => reduceChildRoutes({ ev, item, path }),
      []
    )}
  </Menu>
);

const reduceChildRoutes = ({ ev, path, item }: any) => {
  const key = item.link;

  const exactMatch = item.link
    ? matchPath(
        {
          path: item.link,
          end: true,
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? matchPath(
          {
            path: item.link,
            end: false,
          },
          path
        )
      : false;

    ev.push(
      <Menu.SubMenu
        key={key}
        title={item.name}
        icon={item.icon}
        className={partialMatch ? "ant-menu-item-selected" : ""}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
        })}
      </Menu.SubMenu>
    );
  } else {
    ev.push(
      <Menu.Item
        key={key}
        icon={item.icon}
        className={exactMatch ? "ant-menu-item-selected" : ""}
      >
        <Link to={item.link}>{item.name}</Link>
      </Menu.Item>
    );
  }

  return ev;
};
function SidebarMenu() {
  const { data, isLoading } = useClassifications();
  const location = useLocation();
  const { t } = useTranslation();
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);

  const menuItems: any = [
    {
      heading: "General",
      items: [],
    },
  ];

  if (
    hasPermission(["browse_dashboard"]) &&
    (User?.id == 291 || User?.id == 513)
  )
    menuItems[0].items.push({
      name: t("dashboard"),
      icon: <MdOutlineDashboard />,
      link: "/dashboard",
    });

  if (!isClient(User?.role_id!))
    menuItems[0].items.push({
      name: t("requests"),
      icon: <BsFiles />,
      link: "/client-requests",
    });

  if (isClient(User?.role_id!))
    menuItems[0].items.push({
      name: t("tickets"),
      icon: <IoTicketOutline />,
      link: "/c-tickets",
    });

  if (hasPermission(["browse_technical_profiles"]))
    menuItems[0].items.push({
      name: t("technicals"),
      icon: <GrUserWorker />,
      link: "/technicians",
    });

  if (hasPermission(["browse_companies", "browse_users"]))
    menuItems[0].items.push({
      name: t("customers"),
      icon: <FiUsers />,
      link: "/customers",
      items: [
        {
          name: t("classes"),
          icon: <IoList fontSize="1.1rem" />,
          link: "/customers/classifications",
          items: [],
        },
        {
          name: t("contractors"),
          icon: <FaRegUser fontSize="1.1rem" />,
          link: "/customers/contractors",
        },
      ],
    });

  if (data) {
    // Find the index of the 'customers' menu item
    const customersMenuItemIndex = menuItems[0].items.findIndex(
      (item: any) => item.name == t("customers")
    );

    if (customersMenuItemIndex !== -1) {
      data.data.forEach((item) => {
        menuItems[0].items[customersMenuItemIndex].items[0].items.push({
          name: item.name,
          icon: <></>,
          link: `/customers/classifications/${item.id}`,
        });
      });
    }
  }

  if (hasPermission(["browse_users"]))
    menuItems[0].items.push({
      name: t("users"),
      icon: <TeamOutlined />,
      link: "/users",
    });

  if (User?.role_id === Roles.accountant)
    menuItems[0].items.push({
      name: t("reports"),
      icon: <HiOutlineDocumentReport />,
      link: "/reports",
    });

  if (hasPermission(["browse_final_reports"]))
    menuItems[0].items.push({
      name: t("final_reports"),
      icon: <TbReportAnalytics />,
      link: "/final-reports",
    });

  if (
    User?.role_id === Roles.dispatcher ||
    User?.role_id === Roles.operation ||
    User?.role_id === Roles.sales ||
    User?.role_id === Roles.coordinator
  )
    menuItems[0].items.push({
      name: t("control_panel"),
      icon: <AppstoreOutlined />,
      link: "/control-panel",
      items: [
        {
          name: t("levels"),
          icon: <HiOutlineChartBarSquare />,
          link: "control-panel/levels",
        },
        {
          name: t("services"),
          icon: <GoGear />,
          link: "control-panel/services",
        },
        {
          name: t("services_categories"),
          icon: <BiCategoryAlt />,
          link: "control-panel/categories",
        },
        {
          name: t("customers_categories"),
          icon: <FiUsers />,
          link: "control-panel/clients-categories",
        },
      ],
    });

  if (hasPermission(["browse_settings", "read_settings"]))
    menuItems[0].items.push({
      name: t("system_configuration"),
      icon: <AppstoreOutlined />,
      link: "/system-settings",
    });

  const { isMobile } = useWidth();

  return (
    <Sider width={isMobile ? "100%" : 250} style={{ background: "#fff" }}>
      {menuItems.map((section: any) => (
        <Menu
          key={section.heading}
          mode="inline"
          selectedKeys={[location.pathname]}
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname,
          })}
        </Menu>
      ))}
    </Sider>
  );
}

export default SidebarMenu;
